import { getCookie, setCookie, removeCookie } from 'tiny-cookie';

import { isBrowser } from './browser';

export const setCookies = (name, value, pathString, termination) => {
  setCookie(name, value, { path: pathString, expires: `${termination}D` });
};

export const getCookies = (cookieName) => {
  if (!isBrowser()) return;

  return getCookie(cookieName);
};

export const removeCookies = (cookieName) => removeCookie(cookieName);
