/* istanbul ignore file */
import React, { createContext, FC, useEffect, useMemo, useState } from 'react';
import Client from 'shopify-buy/index.unoptimized.umd';
import { getCookies, removeCookies, setCookies } from 'utils/cookies';
import { currenciesIcons } from './currenciesIcons';
import { CartContextTypes } from './model';
import gtmCustomEvents from '../../utils/gtmCustomEvents';
import normalizeShopifyData from '../../utils/shopifyUtils/normalizeShopifyData';

const checkoutCookieName = 'checkoutId';

export const CartContext = createContext<CartContextTypes.IContextTypes>({
  oldTotalPrice: 0,
  allShopProducts: null,
  shopUrl: '',
  cartItems: [],
  cartItemsQuantity: 0,
  removeLineItems: async () => undefined,
  createClient: () => undefined,
  addProductToCheckout: () => undefined,
  shippingCost: 0,
  updateProductInCheckout: () => undefined,
  totalPrice: 0,
  currencieLabel: '',
  isLoading: false,
  isModalOpen: true,
  setIsModalOpen: () => undefined,
  currentProduct: {},
  setCurrentProduct: () => undefined,
  purchaseSettings: {},
  onSetPurchaseSettings: () => undefined,
});

const CartContextProvider: FC<CartContextTypes.CartContextProviderProps> = ({ children }) => {
  const [currentCheckout, setCurrentCheckout] = useState(null);
  const [cartItems, setCartItems] = useState([]);
  const [shopUrl, setShopUrl] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);
  const [currencyCode, setCurrencyCode] = useState(null);
  const [shippingCost, setShippingCost] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [purchaseSettings, setPurchaseSettings] = useState({});

  const [shopifyData, setShopifyData] = useState(null);
  const [shopifySettings, setShopifySettings] = useState({ isUseShopify: false, lang: 'en-GB' });
  const { isUseShopify, lang } = shopifySettings;

  const { GATSBY_STOREFRONT_ACCESS_TOKEN } = process.env;
  const { GATSBY_SHOPIFY_URL } = process.env;

  const client: Client = useMemo(() => {
    if (isUseShopify) {
      const newClient = Client.buildClient({
        domain: GATSBY_SHOPIFY_URL,
        storefrontAccessToken: GATSBY_STOREFRONT_ACCESS_TOKEN,
        language: lang,
      });

      return newClient;
    }

    return null;
  }, [isUseShopify, lang]);

  const productsQuery = useMemo(
    () =>
      !!client &&
      client.graphQLClient.query((root) => {
        root.addConnection('products', { args: { first: 100 } }, (product) => {
          product.addConnection('variants', { args: { first: 20 } }, (variant) => {
            variant.add('sku');
            variant.add('price');
            variant.add('compareAtPrice');
            variant.add('priceV2', (priceV2) => {
              priceV2.add('currencyCode');
            });
            variant.add('quantityAvailable');
          });
        });
      }),
    [client]
  );

  const createClient = (isShopify, language) => {
    setShopifySettings({ isUseShopify: isShopify, lang: language });
  };

  const mergeAllProductsCartItems = (newCartItems) =>
    newCartItems.map((cartItem) => {
      const quantityAvailable = shopifyData[cartItem.variant.sku]?.quantityAvailable;

      return {
        ...cartItem,
        quantityAvailable,
      };
    });

  const removeCheckout = () => removeCookies(checkoutCookieName);

  const fetchCheckout = async (checkoutId) => {
    if (!checkoutId) return;
    setIsLoading(true);
    client.checkout
      .fetch(checkoutId)
      .then((fetchedCheckout) => {
        if (!fetchedCheckout) return;
        if (fetchedCheckout.completedAt) {
          removeCheckout();

          return;
        }

        const totalPriceValue = Number(fetchedCheckout?.totalPriceV2?.amount).toFixed(2);
        setCurrencyCode(fetchedCheckout?.totalPriceV2?.currencyCode);
        setTotalPrice(totalPriceValue);
        setCurrentCheckout(fetchedCheckout);
        setCartItems(mergeAllProductsCartItems(fetchedCheckout?.lineItems));
        setShopUrl(fetchedCheckout?.webUrl);
        setShippingCost(fetchedCheckout?.shippingLine);
      })
      .catch((error) => {
        console.error(error);
        // TODO removeCheckout(); check error status
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchAllProducts = async () => {
    setIsLoading(true);
    client.graphQLClient
      .send(productsQuery)
      .then((productsInfo) => {
        const data = normalizeShopifyData(productsInfo?.data?.products?.edges);
        setShopifyData(data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (client) {
      const checkoutId = getCookies(checkoutCookieName);

      if (!shopifyData) {
        fetchAllProducts();
      }

      if (checkoutId && shopifyData) {
        fetchCheckout(checkoutId);
      }
    }
  }, [client, JSON.stringify(shopifyData)]);

  const createCheckout = () => {
    if (currentCheckout?.id) return;
    setIsLoading(true);

    return client.checkout
      .create()
      .then((checkout) => {
        setIsLoading(false);
        if (checkout) {
          setCurrentCheckout(checkout);
          setCurrencyCode(checkout?.totalPriceV2?.currencyCode);
          setTotalPrice(checkout?.totalPriceV2?.amount);
          setShopUrl(checkout?.webUrl);
          setCookies(checkoutCookieName, checkout.id, '/', 30);

          return checkout;
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const addProductToCheckout = async (variantId, quantity) => {
    const createdCheckout = await createCheckout();
    const checkout = createdCheckout || currentCheckout;
    const linesToAdd: { variantId?: string; quantity: number }[] = [
      {
        variantId,
        quantity: Number(quantity),
      },
    ];

    setIsLoading(true);

    try {
      const resp = await client.checkout.addLineItems(checkout?.id, linesToAdd);

      setTotalPrice(resp?.totalPrice);
      setShippingCost(resp?.shippingLine);
      setCartItems(mergeAllProductsCartItems(resp?.lineItems));
      setIsLoading(false);

      return mergeAllProductsCartItems(resp?.lineItems);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const updateProductInCheckout = async (lineItemId, quantity) => {
    const linesToUpdate = [
      {
        id: lineItemId,
        quantity: Number(quantity),
      },
    ];

    setIsLoading(true);

    try {
      const resp = await client.checkout.updateLineItems(currentCheckout?.id, linesToUpdate);
      setIsLoading(false);

      setTotalPrice(resp?.totalPrice);
      setCartItems(mergeAllProductsCartItems(resp?.lineItems));
      setShippingCost(resp?.shippingLine);
    } catch (error) {
      setIsLoading(false);

      console.error(error);
    }
  };

  const removeLineItems = (productId) => {
    setIsLoading(true);

    const productToRemove = cartItems.find((cartItem) => cartItem.id === productId);

    const { id, title, quantity } = productToRemove;

    gtmCustomEvents(
      {
        actionField: {
          list: 'Shopping cart',
        },
        remove: {
          products: [{ id, title, quantity, category: 'Bundles', brand: 'Finish' }],
        },
      },
      'eec.remove'
    );

    return client.checkout
      .removeLineItems(currentCheckout.id, [productId])
      .then((resp) => {
        setIsLoading(false);
        setTotalPrice(resp?.totalPrice);
        setCartItems(mergeAllProductsCartItems(resp?.lineItems));
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      }); // think about error-handling
  };

  const onSetPurchaseSettings = (data) => {
    if (data) {
      setPurchaseSettings(data);
    }
  };

  const purchaseSettingsData = useMemo(() => purchaseSettings, [JSON.stringify(purchaseSettings)]);

  const cartItemsQuantity =
    cartItems?.length > 0 ? cartItems?.reduce((acc, item) => acc + item.quantity, 0) : 0;

  const oldTotalPrice =
    cartItems.length > 0
      ? cartItems
          .reduce(
            (acc, cartItem) =>
              cartItem.variant.compareAtPrice
                ? acc + +cartItem.variant.compareAtPrice * cartItem.quantity
                : acc,
            0
          )
          .toFixed(2)
      : 0;

  return (
    <CartContext.Provider
      value={{
        isLoading,
        oldTotalPrice,
        createClient,
        allShopProducts: shopifyData,
        shopUrl,
        cartItems,
        cartItemsQuantity,
        removeLineItems,
        addProductToCheckout,
        shippingCost,
        updateProductInCheckout,
        totalPrice,
        currencieLabel: currenciesIcons[currencyCode],
        isModalOpen,
        setIsModalOpen,
        currentProduct,
        setCurrentProduct,
        purchaseSettings: purchaseSettingsData,
        onSetPurchaseSettings,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
