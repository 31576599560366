import { isBrowser } from './browser';

const gtmCustomEvents = (ecommerce = {}, event = '') => {
  const dataLayer = (isBrowser() && window.dataLayer) || [];

  dataLayer.push({ ecommerce: null });
  dataLayer.push({
    ...(event ? { event } : {}),
    ecommerce,
  });
};

export default gtmCustomEvents;
