const normalizeShopifyData = (shopifyData) => {
  if (!shopifyData?.length) return null;

  const result = {};

  shopifyData.forEach(({ node }) => {
    node.variants?.edges.forEach(({ node: variantNode }) => {
      result[variantNode.sku] = {
        ...variantNode,
      };
    });
  });

  return result;
};

export default normalizeShopifyData;
