import capitalize from '../stringUtils/capitalize';
import { isBrowser } from '../browser';
import { getCookies } from '../cookies';

import {
  IGTMProduct,
  ProductItemType,
  PageViewEventType,
  GenerateLeadEventType,
  SearchResultsViewEventType,
  RetailerClickEventType,
  ProductViewEventType,
  ProductListingViewEventType,
} from './model';

class GtmService {
  eventKeys = {
    event: 'event',
    pageTemplate: 'page_template',
    userId: 'user_id',
    formName: 'form_name',
    searchTerm: 'search_term',
    retailerName: 'retailer_name',
  } as const;

  eventValues = {
    generateLead: 'generate_lead',
    viewSearchResults: 'view_search_results',
    retailerLinkClick: 'retailer_link_click',
    viewItem: 'view_item',
    viewItemList: 'view_item_list',
  } as const;

  productKeys = {
    itemId: 'item_id',
    itemName: 'item_name',
    itemBrand: 'item_brand',
    itemVariant: 'item_variant',
    itemCategory: 'item_category',
    itemListName: 'item_list_name',
    itemCategory2: 'item_category2',
  } as const;

  readonly formNames = {
    newsletter: 'newsletter',
    contactUs: 'contact_us',
    competition: 'competition',
    coupon: 'coupon',
  };

  readonly templateNames = {
    home: 'homepage',
    article: 'article_page',
    product: 'product_page',
    productListing: 'products_list_page',
    search: 'search_results_page',
    contactUs: 'contact_us_page',
    other: 'other_page',
  };

  readonly shopifyUserCookieProperty = 'shopify_user';

  readonly iframeMessageFormSubmitted = 'FORM_SUBMITTED';

  readonly brandName = 'Finish';

  emitPageView(templateName: string): void {
    const pageTemplate = Object.values(this.templateNames).includes(templateName)
      ? templateName
      : this.templateNames.other;

    const shopifyUserId = getCookies(this.shopifyUserCookieProperty);
    const event: PageViewEventType = {
      [this.eventKeys.pageTemplate]: pageTemplate,
      ...(shopifyUserId && { [this.eventKeys.userId]: shopifyUserId }),
    };

    this.emitEvent(event);
  }

  emitSearchResultsView(searchTerm: string): void {
    if (typeof searchTerm !== 'string' || !searchTerm.trim()) return;

    const shopifyUserId = getCookies(this.shopifyUserCookieProperty);
    const event: SearchResultsViewEventType = {
      [this.eventKeys.event]: this.eventValues.viewSearchResults,
      [this.eventKeys.searchTerm]: searchTerm.trim(),
      ...(shopifyUserId && { [this.eventKeys.userId]: shopifyUserId }),
    };

    this.emitEvent(event);
  }

  emitGenerateLead(formName: string): void {
    if (!Object.values(this.formNames).includes(formName)) return;

    const shopifyUserId = getCookies(this.shopifyUserCookieProperty);
    const event: GenerateLeadEventType = {
      [this.eventKeys.event]: this.eventValues.generateLead,
      [this.eventKeys.formName]: formName,
      ...(shopifyUserId && { [this.eventKeys.userId]: shopifyUserId }),
    };

    this.emitEvent(event);
  }

  emitRetailerClick(retailerName: string): void {
    if (typeof retailerName !== 'string' || !retailerName.trim()) return;

    const shopifyUserId = getCookies(this.shopifyUserCookieProperty);
    const event: RetailerClickEventType = {
      [this.eventKeys.event]: this.eventValues.retailerLinkClick,
      [this.eventKeys.retailerName]: retailerName,
      ...(shopifyUserId && { [this.eventKeys.userId]: shopifyUserId }),
    };

    this.emitEvent(event);
  }

  emitProductView(product: ProductItemType): void {
    if (typeof product !== 'object' && !Object.keys(product).length) return;

    const gtmProductItem: IGTMProduct = this.getGtmProductItem(product);
    const shopifyUserId = getCookies(this.shopifyUserCookieProperty);
    const event: ProductViewEventType = {
      [this.eventKeys.event]: this.eventValues.viewItem,
      [this.eventKeys.pageTemplate]: this.templateNames.product,
      ...(shopifyUserId && { [this.eventKeys.userId]: shopifyUserId }),
      ecommerce: {
        items: [gtmProductItem],
      },
    };

    this.emitEvent({ ecommerce: null });
    this.emitEvent(event);
  }

  emitProductListingView(listingName: string, products: ProductItemType[]): void {
    if (
      typeof listingName !== 'string' ||
      !listingName.trim() ||
      !Array.isArray(products) ||
      !products.length
    )
      return;

    const shopifyUserId = getCookies(this.shopifyUserCookieProperty);
    const gtmProductItems = products.map(this.getGtmProductItem.bind(this));
    const event: ProductListingViewEventType = {
      [this.eventKeys.event]: this.eventValues.viewItemList,
      [this.eventKeys.pageTemplate]: this.templateNames.productListing,
      ...(shopifyUserId && { [this.eventKeys.userId]: shopifyUserId }),
      [this.productKeys.itemListName]: listingName,
      ecommerce: {
        items: gtmProductItems,
      },
    };

    this.emitEvent({ ecommerce: null });
    this.emitEvent(event);
  }

  getGtmProductItem({
    title,
    ean,
    skuId,
    link,
    productSize,
    productScent,
  }: ProductItemType): IGTMProduct {
    const productCategory = link?.match(/\/\w+\/([\w-]+)\/?/)?.[1];
    const htmlTagsRegex = /<[^>]+>/g;
    const containsTags = htmlTagsRegex.test(title);
    const productTitle = containsTags ? title?.replace(htmlTagsRegex, '') : title;

    return {
      [this.productKeys.itemBrand]: this.brandName,
      [this.productKeys.itemName]: productTitle,
      ...((ean || skuId) && { [this.productKeys.itemId]: ean || skuId }),
      ...(productCategory && { [this.productKeys.itemCategory]: capitalize(productCategory) }),
      ...(productScent && { [this.productKeys.itemVariant]: productScent }),
      ...(productSize && { [this.productKeys.itemCategory2]: productSize }),
    };
  }

  emitEvent(event: Record<string, unknown>): void {
    if (!isBrowser()) return;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
  }
}

export const gtmService = new GtmService();
