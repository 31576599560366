import React from 'react';

import CartContextProvider from 'context/CartContextProvider';

import { gtmService } from './src/utils/gtmService';

export const wrapRootElement = ({ element }) => (
  <CartContextProvider>{element}</CartContextProvider>
);

export const disableCorePrefetching = () => true;

export const wrapPageElement = ({ props }) => {
  const { pageTemplate } = props.pathContext;
  gtmService.emitPageView(pageTemplate);
};
